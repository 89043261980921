@font-face {
  font-family: 'icomoon';
  src:
    url('../icons/icomoon.ttf?xo9wuc') format('truetype'),
    url('../icons/icomoon.woff?xo9wuc') format('woff'),
    url('../icons/icomoon.svg?xo9wuc#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-reguard-sensor:before {
  content: "\e9c4";
}
.icon-heat-distribution-floor-ceiling:before {
  content: "\e9c3";
}
.icon-pin:before {
  content: "\e9c2";
}
.icon-car:before {
  content: "\e9c1";
}
.icon-frame-bidet:before {
  content: "\e9bf";
}
.icon-frame-urinal:before {
  content: "\e9c0";
}
.icon-close-offer:before {
  content: "\e9be";
}
.icon-save-list:before {
  content: "\e9bd";
}
.icon-pipe-horizontal:before {
  content: "\e9b9";
}
.icon-pipe-vertical:before {
  content: "\e9bc";
}
.icon-add-note:before {
  content: "\e9ab";
}
.icon-add-task:before {
  content: "\e9ac";
}
.icon-comment-small:before {
  content: "\e9ad";
}
.icon-date-time:before {
  content: "\e9ae";
}
.icon-delete:before {
  content: "\e9af";
}
.icon-more-icon-add-phone:before {
  content: "\e9b0";
}
.icon-more-icon-call-customer:before {
  content: "\e9b1";
}
.icon-more-icon-close-offer:before {
  content: "\e9b2";
}
.icon-more-icon-mail-customer:before {
  content: "\e9b3";
}
.icon-more-icon-move:before {
  content: "\e9b4";
}
.icon-more-icon-view:before {
  content: "\e9b5";
}
.icon-rehau:before {
  content: "\e9b6";
}
.icon-to-do-edit:before {
  content: "\e9b7";
}
.icon-to-do-empty-1:before {
  content: "\e9b8";
}
.icon-to-do-empty:before {
  content: "\e9ba";
}
.icon-Union:before {
  content: "\e9bb";
}
.icon-refresh-big:before {
  content: "\e99b";
}
.icon-refresh-list:before {
  content: "\e99c";
}
.icon-check-list:before {
  content: "\e998";
}
.icon-instructions:before {
  content: "\e999";
}
.icon-refresh:before {
  content: "\e99a";
}
.icon-admin-installer-big:before {
  content: "\e9a5";
}
.icon-admin-lead-cold:before {
  content: "\e9a6";
}
.icon-admin-lead-hot:before {
  content: "\e9a7";
}
.icon-admin-lead-warm:before {
  content: "\e9a8";
}
.icon-admin-released-no:before {
  content: "\e9a9";
}
.icon-admin-released-yes:before {
  content: "\e9aa";
}
.icon-montage-summary:before {
  content: "\e9a4";
}
.icon-wind:before {
  content: "\e9a3";
}
.icon-check-benefit:before {
  content: "\e9a2";
}
.icon-house:before {
  content: "\e9a1";
}
.icon-check-circle:before {
  content: "\e99d";
}
.icon-info-tooltip:before {
  content: "\e99e";
}
.icon-visibility-off:before {
  content: "\e99f";
}
.icon-visibility:before {
  content: "\e9a0";
}
.icon-admin-forward:before {
  content: "\e997";
}
.icon-calendar-big:before {
  content: "\e992";
}
.icon-dishwasher:before {
  content: "\e993";
}
.icon-garage:before {
  content: "\e994";
}
.icon-garden-hose:before {
  content: "\e995";
}
.icon-washing-machine:before {
  content: "\e996";
}
.icon-admin-unblock:before {
  content: "\e991";
}
.icon-admin-refresh:before {
  content: "\e98f";
}
.icon-admin-filters:before {
  content: "\e990";
}
.icon-sort:before {
  content: "\e98e";
}
.icon-calendar:before {
  content: "\e98b";
}
.icon-income:before {
  content: "\e98c";
}
.icon-offers:before {
  content: "\e98d";
}
.icon-admin-block:before {
  content: "\e972";
}
.icon-admin-check:before {
  content: "\e973";
}
.icon-admin-close:before {
  content: "\e974";
}
.icon-admin-dashboard:before {
  content: "\e975";
}
.icon-admin-delete:before {
  content: "\e976";
}
.icon-admin-download:before {
  content: "\e977";
}
.icon-admin-dropdown-big:before {
  content: "\e978";
}
.icon-admin-dropdown-small:before {
  content: "\e979";
}
.icon-admin-edit-profile:before {
  content: "\e97a";
}
.icon-admin-edit-setup:before {
  content: "\e97b";
}
.icon-admin-installer:before {
  content: "\e97c";
}
.icon-admin-logout:before {
  content: "\e97d";
}
.icon-admin-management:before {
  content: "\e97e";
}
.icon-admin-map:before {
  content: "\e97f";
}
.icon-admin-more:before {
  content: "\e980";
}
.icon-admin-offers:before {
  content: "\e981";
}
.icon-admin-plus:before {
  content: "\e982";
}
.icon-admin-resend:before {
  content: "\e983";
}
.icon-admin-save:before {
  content: "\e984";
}
.icon-admin-search:before {
  content: "\e985";
}
.icon-admin-sort:before {
  content: "\e986";
}
.icon-admin-user:before {
  content: "\e987";
}
.icon-admin-verify:before {
  content: "\e988";
}
.icon-admin-change-status:before {
  content: "\e989";
}
.icon-admin-overview:before {
  content: "\e98a";
}
.icon-fullscreen:before {
  content: "\e96f";
}
.icon-pause:before {
  content: "\e970";
}
.icon-play:before {
  content: "\e971";
}
.icon-configure:before {
  content: "\e900";
}
.icon-installer:before {
  content: "\e96e";
}
.icon-facebook:before {
  content: "\e96a";
}
.icon-linkedin:before {
  content: "\e96b";
}
.icon-twitter:before {
  content: "\e96c";
}
.icon-youtube:before {
  content: "\e96d";
}
.icon-edit:before {
  content: "\e967";
}
.icon-embed:before {
  content: "\e968";
}
.icon-site-preview:before {
  content: "\e969";
}
.icon-hamburger-small:before {
  content: "\e962";
}
.icon-more:before {
  content: "\e963";
}
.icon-sync:before {
  content: "\e964";
}
.icon-conflict-big:before {
  content: "\e965";
}
.icon-visilibity:before {
  content: "\e966";
}
.icon-water-circulation-system:before {
  content: "\e95e";
}
.icon-water-fresh-module:before {
  content: "\e95f";
}
.icon-water-softener:before {
  content: "\e960";
}
.icon-water-warm-module:before {
  content: "\e961";
}
.icon-heat-distribution-all:before {
  content: "\e95c";
}
.icon-heat-distribution-wall-underflow:before {
  content: "\e95d";
}
.icon-remove:before {
  content: "\e95b";
}
.icon-arrow-big:before {
  content: "\e958";
}
.icon-arrow-small:before {
  content: "\e959";
}
.icon-electric:before {
  content: "\e95a";
}
.icon-hamburger:before {
  content: "\e957";
}
.icon-frame-sink:before {
  content: "\e950";
}
.icon-frame-wc:before {
  content: "\e951";
}
.icon-valve-pressure:before {
  content: "\e952";
}
.icon-valve-vertical:before {
  content: "\e953";
}
.icon-ventilation-system:before {
  content: "\e954";
}
.icon-ventilation-unit:before {
  content: "\e955";
}
.icon-water-distribution:before {
  content: "\e956";
}
.icon-default-icon:before {
  content: "\e93d";
}
.icon-hot-utility-water-tank:before {
  content: "\e93e";
}
.icon-house-1971-1999:before {
  content: "\e93f";
}
.icon-house-from-2000:before {
  content: "\e943";
}
.icon-house-till-1970:before {
  content: "\e944";
}
.icon-linear-installation:before {
  content: "\e945";
}
.icon-raupiano-light:before {
  content: "\e946";
}
.icon-raupiano-plus:before {
  content: "\e947";
}
.icon-regulation-nea-ht:before {
  content: "\e948";
}
.icon-regulation-nea-htc:before {
  content: "\e949";
}
.icon-ring-installation:before {
  content: "\e94a";
}
.icon-service-shower-hoses-aerators:before {
  content: "\e94b";
}
.icon-tee-installation:before {
  content: "\e94c";
}
.icon-water-reducer-with-filter:before {
  content: "\e94d";
}
.icon-water-system-check:before {
  content: "\e94e";
}
.icon-star:before {
  content: "\e94f";
}
.icon-air-heating:before {
  content: "\e901";
}
.icon-arrow-down:before {
  content: "\e902";
}
.icon-arrow-left:before {
  content: "\e903";
}
.icon-arrow-right:before {
  content: "\e904";
}
.icon-arrow-up:before {
  content: "\e905";
}
.icon-back:before {
  content: "\e906";
}
.icon-bath:before {
  content: "\e907";
}
.icon-bathroom:before {
  content: "\e908";
}
.icon-bedroom:before {
  content: "\e909";
}
.icon-bidet:before {
  content: "\e90a";
}
.icon-check:before {
  content: "\e90b";
}
.icon-child-room:before {
  content: "\e90c";
}
.icon-conflict:before {
  content: "\e90d";
}
.icon-control-system:before {
  content: "\e90e";
}
.icon-corridors:before {
  content: "\e90f";
}
.icon-dining-room:before {
  content: "\e910";
}
.icon-district-heating:before {
  content: "\e911";
}
.icon-dressing-room:before {
  content: "\e912";
}
.icon-drinking-water-installation:before {
  content: "\e913";
}
.icon-emoji:before {
  content: "\e914";
}
.icon-minus:before {
  content: "\e915";
}
.icon-gas:before {
  content: "\e916";
}
.icon-geothermal-heating:before {
  content: "\e917";
}
.icon-guest-room:before {
  content: "\e918";
}
.icon-heat-distribution:before {
  content: "\e919";
}
.icon-heat-distribution-ceiling:before {
  content: "\e91a";
}
.icon-heat-distribution-underflow:before {
  content: "\e91b";
}
.icon-heat-distribution-wall:before {
  content: "\e91c";
}
.icon-heating:before {
  content: "\e91d";
}
.icon-heating-cooling:before {
  content: "\e91e";
}
.icon-info:before {
  content: "\e91f";
}
.icon-kitchen:before {
  content: "\e920";
}
.icon-kitchen-sink:before {
  content: "\e921";
}
.icon-living-room:before {
  content: "\e922";
}
.icon-loading:before {
  content: "\e923";
}
.icon-logout:before {
  content: "\e924";
}
.icon-mail:before {
  content: "\e925";
}
.icon-map:before {
  content: "\e926";
}
.icon-no:before {
  content: "\e927";
}
.icon-office:before {
  content: "\e928";
}
.icon-oil:before {
  content: "\e929";
}
.icon-pellet:before {
  content: "\e92a";
}
.icon-phone:before {
  content: "\e92b";
}
.icon-photovoltaic:before {
  content: "\e92c";
}
.icon-pipes:before {
  content: "\e92d";
}
.icon-plus:before {
  content: "\e92e";
}
.icon-rain-shower:before {
  content: "\e92f";
}
.icon-reguard:before {
  content: "\e930";
}
.icon-regulation:before {
  content: "\e931";
}
.icon-resource:before {
  content: "\e932";
}
.icon-save:before {
  content: "\e933";
}
.icon-search:before {
  content: "\e934";
}
.icon-shower:before {
  content: "\e935";
}
.icon-sink:before {
  content: "\e936";
}
.icon-small-sink:before {
  content: "\e937";
}
.icon-solar-panels:before {
  content: "\e938";
}
.icon-storage-room:before {
  content: "\e939";
}
.icon-load-file:before {
  content: "\e93a";
}
.icon-urinal:before {
  content: "\e93b";
}
.icon-washing-wc:before {
  content: "\e93c";
}
.icon-wc:before {
  content: "\e940";
}
.icon-wood:before {
  content: "\e941";
}
.icon-yes:before {
  content: "\e942";
}

