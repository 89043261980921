@font-face {
  font-family: 'Brix Sans';
  src: url('../fonts/brix-sans-light.woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Brix Sans';
  src: url('../fonts/brix-sans-regular.woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Brix Sans';
  src: url('../fonts/brix-sans-medium.woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Brix Sans';
  src: url('../fonts/brix-sans-bold.woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Brix Sans';
  src: url('../fonts/brix-sans-black.woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
